import * as React from 'react';

import { graphql, Link } from 'gatsby';
import Layout from '../../components/layout/layout';
import ActiveMember from '../../page-components/peo/section-3/components/active-member/active-member';
import missionImage from '../../images/missionheader.svg';

function ActiveMemberTemplate({ data, location }) {
  const headerContent = {
    heading: 'PEO DHMS',
    subheading:
      'PEO DHMS is an acquisition organization with a direct reporting relationship to the Office of the Under Secretary of Defense for Acquisition and Sustainment (OUSD(A&amp;S)), and administratively attached to the Defense Health Agency.',
    image: missionImage,
    alt: 'People with a mission',
  };
  return (
    <Layout title="Active Member" headerContent={headerContent} location={location}>
      <div
        className="
                w-full
                mobile:px-[15px]
            "
      >
        <div className="mt-[48px] w-[380px] mx-auto">
          <div className="text-orange lg:text-18 font-roboto font-normal h-[75px]">
            <Link to="/peo"> &lt;&lt; Go Back</Link>
          </div>
        </div>
        <div className="mobile:pb-[41px]">
          <ActiveMember member={data.member} />
        </div>
      </div>
    </Layout>
  );
}

export default ActiveMemberTemplate;

export const pageQuery = graphql`
  query ($id: String) {
    member: contentfulMember(id: { eq: $id }) {
      name
      position
      photo {
        file {
          url
        }
      }
      about {
        about
      }
      book
      sportsTeam
      flowerplant
      food
      movie
      walkUpSong
      yearsInGovernment
      statesVisited
      cupsOfCoffeePerDay
    }
  }
`;
